<template>
	<div class="buy-ur">
		<div v-show="is_mobile">
			<div class="buy-ur_card" :class="{'active': index % 2 === 0}" v-for="(item, index) in card_list" :key="index">
				<div class="head">
					<div class="head-img">
						<img class="head-img_bg" src="@/assets/bg_revoke.svg" alt="">
						<img class="head-img_cover" :src="getCardImgUrl(combi_list[index])" alt="">
					</div>

					<div class="head-text">
						<p>{{ item.zhHans }}</p>
						<p>1500 USDT</p>
					</div>
				</div>
				<div class="label">
					<span>{{ $t('LEFT') }}</span>
					<span class="font-weight-bold">{{100 - left[index]}}/100</span>
				</div>
				<div class="label">
					<span>{{ $t('MINING POWER') }}</span>
					<span>{{ item.mining_power }}</span>
				</div>

				<div class="combined-card" v-for="(subItem, i) in item.combined_list" :key="subItem">
					<div class="combined-card_title">{{ $t('GOD') }}</div>
					<div class="combined-card_text mb-15px">{{ cards[subItem].zhHans }}</div>
					<div class="combined-card_title">{{ $t('NEEDED CARDS') }}</div>
					<div class="combined-card_text">{{ getCombiCard(item, i) }}</div>
				</div>

				<v-btn
						style="height: 44px !important;margin-top: 30px;"
						color="#EB6930"
						large
						rounded
						block
						@click="buyUR(index)"
				>{{ $t('BUY') }}
				</v-btn>
			</div>
		</div>

		<div v-show="!is_mobile">
			<div class="buy-ur_box" :class="{'active': index % 2 === 0}" v-for="(item, index) in card_list" :key="index">
				<div class="buy-ur_item">
					<div class="content">
						<div>
							<div class="content-l">
								<img src="@/assets/bg_revoke.svg" alt="">
								<img class="content-l_img" :src="getCardImgUrl(combi_list[index])" alt="">
							</div>
							<v-btn
									style="height: 52px !important;width: 237px;margin-left: 19.5px;"
									color="#EB6930"
									large
									rounded
									@click="buyUR(index)"
							>{{ $t('BUY') }}
							</v-btn>
						</div>

						<div class="content-r">
							<div class="head">
								<span>{{ showZH ? item.zhHans : item.en }}</span>
								<span>1500 USDT</span>
							</div>
							<div class="content-r_item">
								<span>{{ $t('LEFT') }}</span>
								<span class="font-weight-bold">{{100 - left[index]}}/100</span>
							</div>
							<div class="content-r_item">
								<span>{{ $t('MINING POWER') }}</span>
								<span>{{ item.mining_power }}</span>
							</div>
							<div class="combi-box">
								<table>
									<tr>
										<th>{{ $t('GOD') }}</th>
										<th>{{ $t('NEEDED CARDS') }}</th>
									</tr>
									<tr v-for="(subItem, i) in item.combined_list" :key="subItem">
										<td>{{ showZH ? cards[subItem].zhHans : cards[subItem].en }}</td>
										<td>{{ getCombiCard(item, i) }}</td>
									</tr>
								</table>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<v-dialog v-model="dialog" max-width="760px">
			<div class="c-dialog">
				<div class="c-dialog__head">
					<span>{{ $t('Purchase Success') }}</span>
					<v-btn @click="dialog = false" icon>
						<v-icon color="#EB6930">mdi-close</v-icon>
					</v-btn>
				</div>
				<div class="c-dialog__content">
					<div class="buy-ur_dialog">
						<div class="buy-ur_dialog-img">
							<img class="buy-ur_dialog-bg" src="@/assets/bg_revoke.svg" alt="">
							<img class="img" :src="dialog_img" alt="">
						</div>
						<v-btn
								:style="is_mobile ? mobile_btn_styles : pc_btn_styles"
								color="#EB6930"
								rounded
								large
								@click="dialog = false"
						>{{ $t('confirm') }}</v-btn>
					</div>
				</div>
			</div>
		</v-dialog>
	</div>
</template>

<script>
import cards from '@/data/card'
import { getCardImgUrl } from '@/utils/tools'
import { buyUr, getUrInfo } from '@/utils/cardFactory'
import { mapState, mapMutations } from 'vuex'

export default {
	name: 'BuyUR',
	data () {
		return {
			combi_list: ['5-0', '5-1', '5-2', '5-3', '5-4'],
			cards,
			card_list: [],
			left: [],
			dialog: false,
			pc_btn_styles: {
				height: '52px !important',
			},
			mobile_btn_styles: {
				height: '34px !important',
			},
			dialog_img: '',
		}
	},
	created () {
		this.combi_list.map(item => {
			this.card_list.push(this.cards[item])
		})
		this._getLeft()
	},
	computed: {
		...mapState(['ADDRESS', 'language']),
		is_mobile () {
			return this.$vuetify.breakpoint.mobile
		},
		showZH () {
			return this.language === 'zhHans' || this.language === 'zhHant' || this.language === 'ja'
		},
	},
	methods: {
		...mapMutations(['SET_OVERLAY']),
		getCardImgUrl (filename) {
			return getCardImgUrl(filename)
		},
		async _getLeft () {
			this.left = await getUrInfo()
		},
		getCombiCard (item, index) {
			const names = []
			item.combined[index][0].map(item => {
				names.push(this.showZH ? this.cards[item].zhHans : this.cards[item].en)
			})
			return names.join('、')
		},
		async buyUR (index) {
			this.SET_OVERLAY(true)
			try {
				await buyUr(this.ADDRESS, index)
				this.dialog = true
				this.dialog_img = this.getCardImgUrl(this.combi_list[index])
				this._getLeft()
				this.SET_OVERLAY(false)
			} catch (e) {
				this.SET_OVERLAY(false)
			}
		},
	},
}
</script>

<style lang="scss" scoped>
// m
@media (max-width: 960px) {
	.buy-ur_dialog-img {
		.img {
			width: 240px;
		}
		margin-bottom: 20px;
	}
}
// pc
@media (min-width: 960px) {
	.buy-ur_dialog-img {
		.img {
			width: 340px;
		}
		margin-bottom: 20px;
	}
}
.buy-ur_dialog-bg {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 8;
}
.buy-ur_dialog-img {
	.img {
		z-index: 9;
		padding: 30px;
	}
	img {
		vertical-align: middle;
		object-fit: contain;
	}
	position: relative;
	display: flex;
}
.buy-ur_dialog {
	display: flex;
	flex-direction: column;
	align-items: center;
}
.buy-ur {
	.buy-ur_item {
		.content {
			.content-r {
				.content-r_item {
					display: flex;
					align-items: center;
					justify-content: space-between;
					margin-bottom: 18px;
				}

				.head {
					span {
						&:last-child {
							font-weight: bold;
							font-size: 24px;
							line-height: 36px;
						}

						&:first-child {
							font-weight: bold;
							font-size: 36px;
							line-height: 54px;
							color: #E8E8E8;
						}
					}

					margin-bottom: 38px;
					display: flex;
					align-items: center;
					justify-content: space-between;
				}

				.combi-box {
					table {
						td {
							&:last-child {
								text-align: right;
							}

							padding-top: 25px;
						}

						th {
							&:first-child {
								text-align: left;
							}

							&:last-child {
								text-align: right;
							}
						}

						width: 100%;
					}

					max-width: 600px;
					width: 100%;
					background: #565C65;
					border-radius: 20px;
					padding: 30px;
				}

				flex: 1;
				font-size: 14px;
			}

			.content-l {
				.content-l_img {
					height: 100%;
				}

				img {
					width: 100%;
					position: absolute;
					object-fit: contain;
				}

				width: 276px;
				height: 346px;
				display: flex;
				align-items: center;
				justify-content: center;
				position: relative;
				margin-right: 80px;
				margin-bottom: 80px;
			}

			margin-bottom: 80px;
			display: flex;
		}

		max-width: 956px;
		width: 100%;
	}

	.buy-ur_box {
		display: flex;
		flex-direction: column;
		align-items: center;
		padding: 80px 0;
	}

	.active {
		background-color: #202831;
	}
}
</style>
